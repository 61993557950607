<template>
  <div :class="{'playback-author-list': itemBool == 0, 'playback-author': itemBool == 1}" v-for="item in courseChapterList" :key="item.id">
      <router-link :to="{ name: 'Personal', query: { uid: item.teacherId } }">
          <img :src="item.userImg" />
        </router-link>
      <div class="playback-author-user">
        <p style="color: #666;">{{ item.courseTitle }}</p>
        <router-link :to="{ name: 'Personal', query: { uid: item.teacherId } }">
          {{item.userName }}
        </router-link>
        <div
          class="playback-author-summary"
          style="border-bottom: 0.5px solid #eee;padding-bottom:10.013px;"
          v-if="item.userIntroduce && summaryBool"
        >
          <p>{{ item.userIntroduce }}</p>
        </div>
        <div class="playback-author-summary" v-if="item.courseDes" v-html="item.courseDes"></div>
        <div class="author-views" v-if="item.startTime && itemBool == 0">
          <span>直播时间：{{ compareFormat(item.startTime, item.endTime) }}</span>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { compareFormat } from "@/utils/dateFormat";
export default defineComponent({
    name: "ClassSchCard",
    props: {
        courseChapterList: Array as PropType<object[]>,
        itemBool: {
            type: Number,
            default: 0
        },
        summaryBool: {
          type: Boolean,
          default: true
        }
    },
    setup(props) {

        return {
            compareFormat
        }
    }
});
</script>
<style lang="scss" scoped>
   
  .playback-author, .playback-author-list{
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }

    .playback-author-list{
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 10px;
    }

  .playback-author{
    border-top: 1px solid #eee;
    padding-top: 10px;
  }

  .playback-author > a,   .playback-author-list > a{
    display: block;
  }

  .playback-author > a img,   .playback-author-list > a img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .playback-author-user{
    margin-left: 10px;
    width: 100%;
  }

  .playback-author-user a{
    color: #b9135a;
    font-size: 15px;
    display: block;
    overflow: hidden;
  }

.playback-author-user a span{
  color: #999999;
  float: right;
}

  .playback-author-user p{
    font-size: 15px;
    color: #999;
  }

  .playback-author-summary{
    font-size: 15px;
    line-height: 20px;
    color: #999;
    text-align: justify;
    margin-top: 10px;
  }
  .author-views {
    overflow: hidden;
    margin-top: 15px;
    position: relative;
}
    .author-views span {
        float: right;
        color: #999;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
</style>

<template>
    <div class="author-info">
        <div class="author-time">
            <span>{{ calendarFormat(intro.liveStartTime) && calendarFormat(intro.liveStartTime)[2] }}</span>
            <p>{{ calendarFormat(intro.liveStartTime) && calendarFormat(intro.liveStartTime)[1] }} {{ calendarFormat(intro.liveStartTime) && calendarFormat(intro.liveStartTime)[0] }}</p>
        </div>
        <div class="author">
            <div class="author-intro" v-html="intro.courseDescription"></div>
            <div class="author-views">
                <span>
                    <img src="@/assets/img/icon27.png">
                    <span>{{intro.viewCount}}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { calendarFormat } from "@/utils/dateFormat";

export default defineComponent({
    name: "ClassIntro",
    props: {
        intro: Object
    },
    setup(props) {
        return {
            calendarFormat
        }
    }
});
</script>
<style lang="scss" scoped>
.author-info{
  width: 100%;
  position: relative;
  font-size: 15px;
  color: #999999;
  margin-top: 19.988px;
}
.author-intro{
  color: #999;
  font-size: 15px;
  /*padding-left: 45px;*/
  margin-top: 5.625px;
  overflow: hidden;
  text-align: justify;
}
.author-views{
  overflow: hidden;
  margin-top: 15px;
  position: relative;
}
.author-views span{
  float: right;
  color: #999;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.author-views span img{
  width: 26px;
  height: 15px;
  vertical-align: middle;
  margin-right: 7.5px;
}
.author-time{
  float: left;
}

.author-time span{
  font-size: 22px;
  text-align: center;
  display: block;
}

.author-time p{
  font-size: 14px;
  border-top: 1px solid #e5e5e5;
  padding-top: 5px;
}
.author{
  padding-left: 62px;
}
</style>
